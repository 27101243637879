<template>
    <div class="main-footer">
        <!-- 底部信息 -->
        <div class="footer">
            <div class="Info">
                <ul>
                    <li>
                        <a href="./joinus.html">
                            <p>加入我们</p>
                        </a>
                        <a href="./joinus.html">
                            <p>社会招聘</p>
                        </a>
                        <a href="./schoolRecruitment.html">
                            <p>校园招聘</p>
                        </a>
                    </li>
                    <li>
                        <a href="./contactus.html">
                            <p>联系我们</p>
                        </a>
                        <a href="./contactus.html">
                            <p>联系我们</p>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="companyName">
                <p><a href="https://beian.miit.gov.cn/" style="color:#45b8dc;margin-right:15px">粤ICP备16072482号-6</a>广州百伦供应链科技有限公司</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'main-footer',
    }
</script>

<style scoped lang="scss">
    @import '~@/styles/var.scss';

    .main-footer {
        overflow: hidden;

        .footer-menu {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 100px 0 40px;
            border-bottom: 1px solid #DCDFE6;

            .txt1 {
                color: $--color-primary;
                font-size: 18px;
                font-weight: bold;
            }

            .txt2 {
                margin-top: 27px;
            }

            .txt3 {
                font-size: 16px;
            }

            .txt5 {
                margin-top: 18px;
            }

            .block1 {
                width: 260px;
            }

            .block2 {
                display: flex;
                align-items: center;
            }

            .block3 {
                width: 100px;

                .items {
                    margin-top: 27px;

                    li {
                        margin-bottom: 13px;
                    }
                }
            }

            .block4 {
                width: 260px;
            }

            .img1 {
                width: 28px;
                height: 29px;
                margin-right: 8px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .footer-copyright {
            padding: 20px 0;

            .block1 {
                text-align: center;
            }

            .txt1 {

            }

            .txt2 {
                color: $--color-primary;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .main-footer {
            .footer-menu {
                .block1,.block3,.block4 {
                    width: 100%;
                    margin-bottom: 30px;
                    text-align: center;
                }

                .block2 {
                    justify-content: center;
                }
            }
        }
    }
</style>
