<template>
    <div class="main-header">
        <div class="header-tap">
            <div class="left">
                <a href="./index.html">
                    <img src="http://img.blsct.com/logo.png" alt="">
                </a>
                <h1>广州百伦供应链科技有限公司</h1>
            </div>
            <div class="right">
                <ul class="navList">
                    <li class="navItem">
                        <a href="company.html">公司</a>
                        <ul class="list">
                            <li>
                                <a href="./company.html#show-Info">公司简介</a>
                            </li>
                            <li>
                                <a href="./company.html#mission">公司使命</a>
                            </li>
                            <li>
                                <a href="./company.html#culture">公司文化</a>
                            </li>
                            <li>
                                <a href="./company.html#office-env">办公环境</a>
                            </li>
                            <li>
                                <a href="./company.html#address">公司地址</a>
                            </li>
                        </ul>
                    </li>
                    <li class="navItem">
                        <a href="staff.html">员工</a>
                        <ul class="list">
                            <li>
                                <a href="staff.html#talent">人才发展</a>
                            </li>
                            <li>
                                <a href="staff.html#training">人才培养</a>
                            </li>

                        </ul>
                    </li>
                    <li class="navItem">
                        <a href="./bailunLife.html">百伦活动</a>
                    </li>
                    <li class="navItem">
                        <a href="./joinus.html">加入我们</a>
                        <ul class="list">
                            <li>
                                <a href="./joinus.html">社会招聘</a>
                            </li>
                            <li>
                                <a href="./schoolRecruitment.html">校园招聘</a>
                            </li>
                        </ul>
                    </li>
                    <li class="navItem">
                        <a href="./contactus.html">联系我们</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="header-tap-mobile">
            <div class="left" id="sidebar">
                <p>...</p>
            </div>
            <div class="right">
                <a href="./index.html">
                    <img src="http://img.blsct.com/logo.png" alt="">
                </a>
                <h1>广州百伦供应链科技有限公司</h1>
            </div>
        </div>
        <div class="sidebar_div">
            <ul class="navList">

                <li class="navItem">
                    <a href="./index.html">首页</a>
                    <a>公司</a>
                    <ul class="list">
                        <li>
                            <a href="./company.html#show-Info">公司简介</a>
                        </li>
                        <li>
                            <a href="./company.html#mission">公司使命</a>
                        </li>
                        <li>
                            <a href="./company.html#culture">公司文化</a>
                        </li>
                        <li>
                            <a href="./company.html#office-env">办公环境</a>
                        </li>
                        <li>
                            <a href="./company.html#address">公司地址</a>
                        </li>
                    </ul>
                </li>
                <li class="navItem">
                    <a>员工</a>
                    <ul class="list">
                        <li>
                            <a href="staff.html#talent">人才发展</a>
                        </li>
                        <li>
                            <a href="staff.html#training">人才培养</a>
                        </li>

                    </ul>
                </li>
                <li class="navItem">
                    <a href="./bailunLife.html">百伦活动</a>
                </li>
                <li class="navItem">
                    <a>加入我们</a>
                    <ul class="list">
                        <li>
                            <a href="./joinus.html">社会招聘</a>
                        </li>
                        <li>
                            <a href="./schoolRecruitment.html">校园招聘</a>
                        </li>
                    </ul>
                </li>
                <li class="navItem">
                    <a href="./contactus.html">联系我们</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'main-header',

        data() {
            return {
                list: []
            }
        },

        methods: {
            jump(item) {
                this.list.forEach((item) => {
                    item.is_active = false;
                });
                item.is_active = true;
            }
        },

        async created() {
            await this.$nextTick();
            const { fullPath, hash } = this.$route;
            const target = this.list.find(item => item.href === fullPath);
            if(target) target.is_active = true;
            setTimeout(() => {
                if (hash) document.querySelector(hash).scrollIntoView();
            }, 100);
        }
    }
</script>

<style scoped lang="scss">
    @import '~@/styles/var.scss';

    .main-header {
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        background-color: transparent;

        .page-width {
            display: flex;
            align-items: center;
        }

        .logo {
            display: flex;
            align-items: center;
            margin-right: 413px;
            white-space: nowrap;

            .txt1 {
                color: $--color-primary;
                font-size: 18px;
                font-weight: bold;
            }

            .txt2 {
                margin: 0 8px 0 14px;
                color: $--color-text-lightest;
                font-size: 12px;
            }

            .txt3 {
                color: $--color-text-lightest;
                font-size: 12px;
            }

            img {
                width: 186px;
            }
        }

        .menu-list {
            display: flex;
            align-items: center;

            .menu-list__item {
                position: relative;
                height: 80px;
                line-height: 80px;
                margin-right: 30px;

                ::v-deep .el-link {
                    white-space: nowrap;
                    color: $--color-white;

                    .el-link__inner {
                        font-size: 24px;
                    }
                }

                &.is-active {
                    .el-link {
                        color: $--color-white;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        height: 2px;
                        background-color: transparent;
                    }
                }
            }
        }

        .contact-us {
            margin-right: 0;
            margin-left: auto;
            white-space: nowrap;
        }
    }
</style>
