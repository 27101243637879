import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import './assets/scss/common.scss'

router.beforeEach((to, from, next) => {
    if (location.host === 'blsct.com') {
        document.title = '广州百伦官网'
    }

    if (location.host === 'bailuntec.com') {
        document.title = '广州百伦办公系统'
    }

    next();
});

createApp(App)
    .use(router)
    .use(ElementPlus)
    .mount('#app');
