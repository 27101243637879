<template>
    <div class="app-wrapper">
        <div class="main-container">
            <main-header />
            <section class="app-main">
                <transition name="fade-transform" mode="out-in">
                    <keep-alive>
                        <router-view :key="key" />
                    </keep-alive>
                </transition>
            </section>
            <main-footer />
        </div>
    </div>
</template>

<script>
    import mainHeader from './main-header.vue'
    import mainFooter from './main-footer.vue'

    export default {
        name: 'index',

        components: {
            mainHeader,
            mainFooter
        },

        computed: {
            key() {
                return this.$route.path
            }
        }
    }
</script>

<style scoped lang="scss">
    .app-wrapper {
        .app-main {
            overflow: hidden;
        }
    }
</style>
